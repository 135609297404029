import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
import {
  LiquidityProvider,
  PaginatedQuery,
  PaginatedResponse,
  Products,
  VersionedObject,
  standardiseQuerySort,
} from '../models';

export class LiquidityProviderService extends HttpBasedService {
  constructor(http: HttpService, protected baseUrl: string, group = 'clientCollateralService') {
    super(http, {
      options: {
        errors: { messageDefaults: { group } },
      },
    });
  }

  public getLiquidityProviderAssignments(
    clientId: string,
    instrumentType: Products,
    options?: HttpOptions<PaginatedResponse<LiquidityProvider>>
  ) {
    return this.get<PaginatedResponse<LiquidityProvider>>(`${this.baseUrl}liquidity-providers/assignments`, {
      axiosConfig: {
        params: {
          clientId,
          instrumentType,
        },
      },
      options,
    });
  }

  public getLiquidityProvidersCapabilities(params: PaginatedQuery) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<Partial<LiquidityProvider>>>(`${this.baseUrl}liquidity-providers/capabilities`, {
      axiosConfig: { params },
    });
  }

  public updateLiquidityProvidersAssignment(
    id: string,
    model: Partial<LiquidityProvider>,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    return this.put<VersionedObject>(`${this.baseUrl}liquidity-providers/assignments/${id}`, model, {
      options,
    });
  }
}
